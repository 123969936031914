import { ref, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { _fetchGraphs } from '@/composables/campaign/cmsi/report';

import {
  CmSalesImpactGraphData,
  CmSalesImpactProductsDetails
} from '@/api/openapi';

import useLoading from '@/composables/loading';
import { _fetch } from '@/composables/campaign/cmsi/product';

interface ListWeeklyMonitor {
  graphs: Ref<CmSalesImpactGraphData>;
  isLoading: Ref<boolean>;
  product: Ref<CmSalesImpactProductsDetails>;
}

export const useListWeeklyMonitor = ({
  productId
}: {
  productId: number;
}): ListWeeklyMonitor => {
  const route = useRoute();
  const { params } = route;
  const companyId = Number(params.companyId);
  const graphs = ref({} as CmSalesImpactGraphData);
  const product = ref({} as CmSalesImpactProductsDetails);

  // モーダル連動
  // const [isLoading, fetchGraphs] = useLoading(_fetchGraphs);
  const [isLoading, fetchGraphs] = useLoading(
    async ({
      reportId,
      companyId,
      productId
    }: {
      reportId?: number | undefined;
      companyId: number;
      productId: number;
    }) => {
      const graphData = await _fetchGraphs({ reportId, companyId, productId });
      const productsDetail = await _fetch({ companyId, productId });
      return { graphData, productsDetail };
    }
  );

  (async () => {
    // レポートグラフを取得
    const { graphData, productsDetail } = await fetchGraphs({
      companyId,
      productId
    });
    graphs.value = graphData;
    product.value = productsDetail;
  })();

  return {
    graphs,
    isLoading,
    product
  };
};
